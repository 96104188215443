import React from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PatientExperience from "./components/patient-experience/PatientExperience";
import AnalysisLibrary from "./components/analysis-library/AnalysisLibrary";
import Explore from "./components/explore/Explore";
import ConcurrentSession from "./components/concurrent-session/ConcurrentSessionModal";
import Home from "./components/Home";
import SurveyQuestions from "./components/survey-questions/SurveyQuestions";
import LandingPage from "./components/landing-page/LandingPage";
import ProtectedRoute from "./ProtectedRoute";

function AppRoutes() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  function MissingRoute() {
    console.log("userInfo?.landing_visible ----", userInfo?.landing_visible);
    return userInfo?.landing_visible && userInfo?.kural_visible ? (
      <Navigate to="/landing" />
    ) : (
      <Navigate to="/explore" />
    );
  }

  return (
    <Routes>
      <Route
        exact
        path="/analysis-library"
        element={
          <ProtectedRoute>
            <AnalysisLibrary />
          </ProtectedRoute>
        }
      />
      <Route path="/patient-experience" element={<PatientExperience />} />
      {/* <Route path="/explore" element={ <Explore />} /> */}
      <Route
        path="/explore"
        element={
          <ProtectedRoute>
            <Explore />
          </ProtectedRoute>
        }
      />
      <Route path="/home" element={<Home />} />
      {/* <Route path="/session" element={<ConcurrentSession />} /> */}
      <Route
        path="/survey-questions"
        element={
          <ProtectedRoute>
            <SurveyQuestions />
          </ProtectedRoute>
        }
      />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="*" element={<MissingRoute />} />
    </Routes>
  );
}

export default AppRoutes;
