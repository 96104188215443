import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Tooltip, Card } from "antd";
import { InfoCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "./LandingPage.scss";
import LandingPageHeader from "../../assets/icons/landing-page-header.svg";
import { ReactComponent as Icon1 } from "../../assets/icons/landing-page-icon-1.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/landing-page-icon-2.svg";
import { logout } from "../../services/ExperienceBankAPI";
import LillyIcon from "../../assets/icons/Lilly-logo.svg";
import GileadIcon from "../../assets/icons/Gilead-logo.svg";

function LandingPage() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const brandName = "Patient Experience Bank";
  const brandDescription =
    "Patients have been saying “nothing about us without us” for decades. Let us make patient-centered decisions today using their voice.";
  const environment = userInfo?.environment || "";
  const logoMap = {
    dev: LillyIcon,
    qa: "",
    demo:"",
    prod_client_1: LillyIcon,
    prod_gild: GileadIcon,
    prod_aszn: "",
  };

  const clientLogo = logoMap[environment] || "";

  const cardData = [
    {
      id: "bank_of_standards",
      title: "Bank of Standards",
      description:
        "Explore survey-based patient insights from thousands of diverse patients globally using custom cohorts.",
      icon: <Icon1 style={{ width: 24, height: 24, marginRight: 8 }} />,
      tooltip:
        "Voice of global patients across 20 MM+ datapoints captured via surveys.",
      redirectionPath: "/explore",
    },
    {
      id: "bespoke_insights",
      title: "Bespoke Insights",
      description:
        "Generate patient insights from audio or text-based data using GenAI.",
      icon: <Icon2 style={{ width: 24, height: 24, marginRight: 8 }} />,
      tooltip:
        "Voice of global patients through transcripts, calls, PPTs, PDFs etc.",
      redirectionPath: "",
    },
  ];

  // Filter card data based on 'kural_visible'
  const visibleCards = userInfo?.kural_visible
    ? cardData
    : cardData.filter((card) => card.id !== "bespoke_insights");

  const navigateTo = (path) => {
    console.log(path);
    if (path === "/explore") {
      navigate(path);
    } else {
      logOutFromPEB();
      setTimeout(() => {
        window.open(process.env.REACT_APP_ENV_SWITCH_URL, "_self");
      }, 1200);
      // window.open(process.env.REACT_APP_ENV_SWITCH_URL, "_self");
    }
  };

  const logOutFromPEB = async () => {
    const payload = {
      user_id: localStorage.getItem("user_id"),
      logout_from: "to_kural",
    };
    try {
      let response = await logout(payload);
      console.log("logout from PEB", response);
    } catch (error) {
      console.log("Logout error ", error);
    }
  };

  return (
    <>
      <Row>
        <Col span={24} className="landing-header">
          <Row
            style={{
              width: "100%",
              height: "196px",
              backgroundImage: `url(${LandingPageHeader})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}>
            <Col span={12} style={{ paddingLeft: 32, top: "20%" }}>
              <span className="brand-name">{brandName}</span>
              <p className="brand-description">{brandDescription}</p>
            </Col>
            <Col span={12} style={{ paddingRight: 50, textAlign: "right", top: "5%" }}>
              <img src={clientLogo} alt={`${environment} logo`} style={{ height: 80 }} />
            </Col>
          </Row>
        </Col>
        <div className="cards-div">
          <Row style={{ gap: 16 }}>
            {visibleCards.map((card, index) => (
              <div key={index} style={{ width: 480, height: 180 }}>
                <Card
                  style={{
                    borderRadius: "1px",
                    borderColor: "#E6E6E6",
                    padding: 24,
                  }}
                  hoverable
                  onClick={() => navigateTo(card?.redirectionPath)}>
                  <div style={{ display: "flex" }}>
                    <div> {card.icon}</div>
                    <div>
                      <span className="landing-card-header">
                        {card.title}{" "}
                        <Tooltip
                          title={card.tooltip}
                          overlayStyle={{ width: 300 }}>
                          <InfoCircleOutlined style={{ color: "#27A6A4" }} />
                        </Tooltip>
                      </span>
                      <br />
                      <p>{card.description}</p>
                    </div>
                  </div>
                  <ArrowRightOutlined
                    style={{ float: "right", color: "#27A6A4" }}
                  />
                </Card>
              </div>
            ))}
          </Row>
        </div>
      </Row>
    </>
  );
}

export default LandingPage;
