import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log("userInfo", userInfo);
  if (!userInfo?.session_id) {
    // Redirect to landing page if not authenticated
    return <Navigate to="/home" />;
  }

  return children;
};

export default ProtectedRoute;
